// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-list-jsx": () => import("./../src/pages/list.jsx" /* webpackChunkName: "component---src-pages-list-jsx" */),
  "component---src-templates-article-tsx": () => import("./../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */)
}

