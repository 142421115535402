import * as classNames from 'classnames';
import * as easings from 'd3-ease';
import * as React from 'react';
import * as ReactModal from 'react-modal';

import reactSizes from 'react-sizes';
import { Spring } from 'react-spring';
import { compose, mapProps, StateHandler, StateHandlerMap, withStateHandlers } from 'recompose';
import { noop } from '../../../helpers/noop';
import { E2E_TEST_CLASS_NAME } from './Modal.test.consts';

export { CloseButton } from './CloseButton';

const styles = require('./Modal.less');

export type ModalProps = {
  dialog?: boolean;
  notAttachedToTop?: boolean;
  fullscreen?: boolean;
  className?: string;
  overlayClassName?: string;

  onAfterOpen?: () => void;

  onRequestClose?: () => void;

  isOpen: boolean;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  style?: {
    content?: React.CSSProperties;
    overlay?: React.CSSProperties;
  }
};

export const setAppElement = ReactModal.setAppElement;

const parentSelector = (): HTMLElement => document.querySelector('.modal-placeholder') || document.body;

export const Modal: React.FC<ModalProps> = props => {
  const {
    children,
    isOpen,
    onAfterOpen,
    onRequestClose,
    shouldCloseOnOverlayClick,
    notAttachedToTop,
    shouldCloseOnEsc,
    style: { content = {}, overlay = {} } = {},
  } = props;
  return (
    <Spring
      from={{ opacity: 0 }}
      to={{ opacity: isOpen ? 1 : 0 }}
      config={{ duration: 150, easing: easings.easeQuadInOut }}
    >
      {(params) => {
        const opacity = params['opacity'] || 0;

        return (
          opacity !== 0 && (
            <ReactModal
              parentSelector={parentSelector}
              closeTimeoutMS={500}
              className={{
                base: classNames(E2E_TEST_CLASS_NAME, styles.container, props.className, {
                  [styles.fullscreen]: props.fullscreen,
                  [styles.dialog]: props.dialog,
                  [styles.notAttachedToTop]: notAttachedToTop,
                }),
                afterOpen: styles.afterOpen,
                beforeClose: styles.beforeClose,
              }}
              overlayClassName={{
                base: classNames(styles.overlay, props.overlayClassName, {
                  [styles.fullscreen]: props.fullscreen,
                  [styles.dialog]: props.dialog,
                }),
                afterOpen: styles.afterOpen,
                beforeClose: styles.beforeClose,
              }}
              style={{ content, overlay: { opacity, ...overlay } }}
              isOpen={opacity !== 0}
              onAfterOpen={onAfterOpen ? onAfterOpen : noop}
              onRequestClose={onRequestClose}
              shouldCloseOnOverlayClick={shouldCloseOnOverlayClick || false}
              shouldCloseOnEsc={shouldCloseOnEsc}
            >
              {children}
            </ReactModal>
          )
        );
      }}
    </Spring>
  );
};

export interface WindowSize {
  windowHeight: number;
}

export interface ContentHeightState {
  contentHeight: number;
}

export interface ContentHeightUpdater extends StateHandlerMap<ContentHeightState> {
  onChangeContentHeight: StateHandler<ContentHeightState>;
}

export const MODAL_BOTTOM_MARGIN = 30;
export const MODAL_HEADER_HEIGHT = 60;
export const MODAL_FOOTER_HEIGHT = 80;

function getModalHeight(windowHeight: number, contentHeight: number) {
  const modalHeight = windowHeight - MODAL_BOTTOM_MARGIN;

  return modalHeight > contentHeight
    ? contentHeight
    : modalHeight;
}

function getModalBodyMaxHeight(windowHeight: number, contentHeight: number) {
  const modalBodyMaxHeight = windowHeight - MODAL_BOTTOM_MARGIN - MODAL_HEADER_HEIGHT - MODAL_FOOTER_HEIGHT;

  return modalBodyMaxHeight > contentHeight
    ? contentHeight
    : modalBodyMaxHeight;
}

type ModalSizeEnhancerInnerProps = WindowSize & ContentHeightState & {
  onChangeContentHeight(contentWidth: number, contentHeight: number): number;
};

export type ModalSizeEnhancerOuterProps = {
  onChangeContentHeight(contentWidth: number, contentHeight: number): number;
  modalHeight: number;
  modalBodyMaxHeight: number;
};

export const modalHeightEnhancer = <InnerProps, OuterProps>() =>
  compose<InnerProps & ModalSizeEnhancerOuterProps & OuterProps, OuterProps>(
    withStateHandlers<ContentHeightState, ContentHeightUpdater, {}>(
      () => ({ contentHeight: 0 }),
      {
        onChangeContentHeight: (state) => (contentWidth, contentHeight) => {
          return ({ ...state, contentHeight });
        },
      },
    ),
    reactSizes(({ height }) => ({
      windowHeight: height,
    })),
    mapProps<ModalSizeEnhancerOuterProps, ModalSizeEnhancerInnerProps>(
      (props) => ({
        ...props,
        modalHeight: getModalHeight(props.windowHeight, props.contentHeight),
        modalBodyMaxHeight: getModalBodyMaxHeight(props.windowHeight, props.contentHeight),
        onChangeContentHeight: props.onChangeContentHeight,
      })),
  );
